/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import useTracking from 'components/Globals/Analytics';
import classnames from 'classnames';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';

import Loader from 'components/uiLibrary/Loader';
import NoResult from 'components/Globals/NoResult';
import CommonTrans from 'components/Globals/CommonTrans';
import LinkButton, { PrimaryButton, TertiaryButton } from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Checkbox from 'components/uiLibrary/Inputs/Checkbox';
import Typography from 'components/uiLibrary/Typography';
import SearchInput from 'components/uiLibrary/FormInputs/SearchInput';
import EntityName from 'components/Globals/EntityName';
import { CATEGORIES, GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { TP, AGGREGATION_TYPES, URL_SLUG_TYPES, SORT_OPTION_VALUES, PAGE_SOURCE, ENTITY_TYPE } from 'constants/index';
import { FILTER_AGGREGATION_MAP, FILTER_SLUG_TYPE, FILTER_LABELS, FILTER_TYPES } from 'constants/filters';

import usePageContext from 'utils/hooks/usePageContext';
import { getWorkTitle } from 'utils/works';
import { trackSearchFilter, trackSearch } from 'utils/tracking';
import { useTranslation } from 'src/i18n';
import { useQuery } from 'utils/react-query';
import { getCityCountry, determineEntityTypeFromAggregation } from 'utils/globals';

import queries from 'containers/Productions/queries';

import classes from './AggregationFilter.module.scss';

// TODO: Move in future need to check with @sarthak.
const PLACEHOLDERS = {
  [FILTER_TYPES.SEASON]: {
    // SINGLE: `${TP}.FN_SEASON`,
    MULTIPLE: `${TP}.m_SEASON`,
  },
  [FILTER_TYPES.SURTITLE]: {
    // SINGLE: `${TP}.m_SUBTITLES`,
    MULTIPLE: `${TP}.m_SUBTITLES`,
  },
  [FILTER_TYPES.CAST_CREW]: {
    // SINGLE: `${TP}.FN_CAST_AND_CREW`,
    MULTIPLE: `${TP}.FN_CAST_AND_CREW`,
  },
  [FILTER_TYPES.CONDUCTOR]: {
    // SINGLE: `${TP}.m_CONDUCTOR`,
    MULTIPLE: `${TP}.FN_CONDUCTORS`,
  },
  [FILTER_TYPES.DIRECTOR]: {
    // SINGLE: `${TP}.FN_DIRECTOR`,
    MULTIPLE: `${TP}.FN_DIRECTORS`,
  },
  [FILTER_TYPES.COMPANY]: {
    // SINGLE: `${TP}.m_COMPANY`,
    MULTIPLE: `${TP}.m_OPCOS`,
  },
  [FILTER_TYPES.FESTIVAL]: {
    // SINGLE: `${TP}.FN_FESTIVAL`,
    MULTIPLE: `${TP}.m_FESTIVALS`,
  },
  [FILTER_TYPES.LANGUAGE]: {
    // SINGLE: `${TP}.FN_LANGUAGE_LABEL`,
    MULTIPLE: `${TP}.m_LANGUAGES`,
  },
  [FILTER_TYPES.VENUE]: {
    // SINGLE: `${TP}.m_VENUE`,
    MULTIPLE: `${TP}.m_VENUES`,
  },
  [FILTER_TYPES.ORCHESTRA]: {
    // SINGLE: `${TP}.m_FN_ORCHESTRA`,
    MULTIPLE: `${TP}.LP_ORCHESTRAS`,
  },
  [FILTER_TYPES.CHORUS]: {
    // SINGLE: `${TP}.m_FN_CHORUS`,
    MULTIPLE: `${TP}.FN_CHORUSES`,
  },
  [FILTER_TYPES.CITY]: {
    // SINGLE: `${TP}.m_CITY`,
    MULTIPLE: `${TP}.FN_CITIES`,
  },
  [FILTER_TYPES.WORK]: {
    // SINGLE: `${TP}.FN_WORK`,
    MULTIPLE: `${TP}.FN_MUSICAL_WORKS_SECTION_NAME`,
  },
  [FILTER_TYPES.CREATOR]: {
    // SINGLE: `${TP}.m_COMPOSER`,
    MULTIPLE: `${TP}.m_COMPOSERS`,
  },
};

const FilterOptions = ({ type, options = [], selectedOptionIds, onChange, aggregationType, trackingData }) => {
  const optionsList = useMemo(() => {
    if (!selectedOptionIds?.length) {
      return options;
    }
    return options?.sort((a, b) => {
      if (selectedOptionIds?.includes(a?.id)) {
        if (selectedOptionIds?.includes(b?.id)) {
          return 0;
        }
        return -1;
      }
      return 1;
    });
    // NOTE: We don't want to re-sort the options when the selectedOptionIds change, because UI will be jumpy
  }, [options]);

  if (optionsList?.length > 0) {
    return (
      <div className={classes.filterOptions}>
        {optionsList?.map(option => {
          const isChecked = selectedOptionIds?.includes(option?.id);
          let label = option?.cityCountry ? `${option?.label} (${option?.cityCountry})` : option?.label;

          if (option?.total) {
            label += ` (${option?.total})`;
          }

          return (
            <Checkbox
              key={`${type}_${option?.id}`}
              onChange={(e, checkedStatus) => {
                onChange(option, checkedStatus);
              }}
              label={
                <EntityName
                  name={label}
                  entity={{ id: option?.id, name: label }}
                  entityType={determineEntityTypeFromAggregation(aggregationType)}
                  isRaw
                  trackingData={trackingData}
                />
              }
              checked={isChecked}
              size="small"
            />
          );
        })}
      </div>
    );
  }

  return <NoResult title="No options" sizes={{ icon: 40, title: 14 }} />;
};

const LockedFilters = ({ onAccessArchives, isLoggedIn, isRestricted, limitReached }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  if (!isRestricted) {
    return null;
  }

  return (
    <div className={classes.lockedFiltersModal}>
      <div className={classes.lockedFiltersModal__content}>
        <div className={classes.lockedFiltersModal__content_icon}>
          <SpriteIcon icon="tune" />
        </div>
        {isLoggedIn ? (
          <Typography size={16} weight="medium" variant="p" align="center">
            <CommonTrans
              ns="NS_APP_GLOBALS"
              i18nKey={
                limitReached ? t(`${TP}.FN_FILTER_LIMIT_UPGRADE_MSG`) : t(`${TP}.FN_FILTER_ARCHIVED_UPGRADE_MSG`)
              }
            />
          </Typography>
        ) : (
          <Typography size={16} weight="medium" variant="p" align="center">
            {t(`${TP}.FN_FILTERS_LOGIN_REQUIRED`)}
          </Typography>
        )}
        <PrimaryButton
          styles={{
            root: classes.lockedFiltersModal__cta_primary,
          }}
          shape="rectangle"
          onClick={onAccessArchives}
          stretch
        >
          {isLoggedIn ? t(`${TP}.AS_SUBSCRIPTIONS_UPGRADE`) : t(`${TP}.m_LOGIN`)}
        </PrimaryButton>
        {isLoggedIn && (
          <TertiaryButton shape="rectangle" rightIcon={<SpriteIcon icon="chevron_right" />} stretch>
            {t(`${TP}.FN_BOOK_SALES_MEETING`)}
          </TertiaryButton>
        )}
        {!isLoggedIn && (
          <Typography variant="p" align="center" size={11} color="secondary">
            {t(`${TP}.FN_FILTERS_LOCKED_FOOTNOTE`)}
          </Typography>
        )}
      </div>
    </div>
  );
};

const AggregationFilter = ({
  type,
  total = 0,
  commonFilterParams,
  onUpdateFilters,
  asPopover = true,
  onAccessArchives,
  inline = false,
  upcoming = false,
  trackingData = {},
}) => {
  const track = useTracking();
  const { t } = useTranslation('NS_APP_GLOBALS');
  const [query, setQuery] = useState('');
  const { filters, entityType, entityId, getFilterUsageState } = usePageContext();

  const filterState = useMemo(() => getFilterUsageState({ type, upcoming }), [getFilterUsageState, type, upcoming]);

  const aggregationType = FILTER_AGGREGATION_MAP[type];
  const appliedFilters = useMemo(() => {
    const slugType = FILTER_SLUG_TYPE[type];

    return filters?.[slugType];
  }, [type, filters]);
  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (appliedFilters?.length > 0) {
      return appliedFilters;
    }

    return [];
  });

  useEffect(() => {
    setSelectedOptions(() => {
      if (appliedFilters?.length > 0) {
        return appliedFilters;
      }

      return [];
    });
  }, [appliedFilters, type]);

  const selectAggregationData = useCallback(
    response => {
      const transFormData = response?.data?.map(option => ({
        id: option?.id,
        slug: option?.slug,
        value: option?.id || option?.slug,
        label: option?.name,
        ...(aggregationType === AGGREGATION_TYPES.WORK && { label: getWorkTitle(option, true, true) }),
        ...(aggregationType === AGGREGATION_TYPES.CITY && {
          label: `${option.name}${option?.country?.name ? `, ${option?.country?.name}` : ''}`,
        }),
        ...((option?.country?.name || option?.city?.name) && { cityCountry: getCityCountry(option) }),
        total: option?.total,
      }));

      const top5 = transFormData?.slice(0, 5);

      const sortByNames = transFormData?.sort((a, b) => {
        const nameA = a?.label?.toLowerCase();
        const nameB = b?.label?.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      return {
        data: sortByNames,
        top5,
      };
    },
    [aggregationType],
  );

  const select = useMemo(() => selectAggregationData, [selectAggregationData]);
  const filterParams = {
    ...omit(commonFilterParams, [aggregationType, URL_SLUG_TYPES.BOOLEAN_SEARCH]),
    ...(query && { aggregation_query: query }),
    ...([ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType) &&
      entityId && {
        page_source: entityType === ENTITY_TYPE.ARTIST ? PAGE_SOURCE.INDIVIDUAL : PAGE_SOURCE.ORGANIZATION,
      }),
    sort: SORT_OPTION_VALUES.PROD_COUNT_DESC,
  };

  const { data, isFetching: isLoading } = useQuery(
    queries.getFilterOptions({
      filters: filterParams,
      aggregationType,
      queryConfig: {
        keepPreviousData: true,
        enabled: !!aggregationType && !filterState?.isRestricted,
        select,
        onSuccess: () => {
          if (query?.length > 0) {
            track.click(trackSearch(aggregationType, query), GOOGLE_OLD_TRACKING_SERVICES);
          }
        },
      },
    }),
  );

  const { entityList, top5 } = useMemo(() => {
    if (filterState?.isRestricted) {
      return {
        entityList: Array.from({ length: 5 }, () => ({ id: -1, label: '#######', total: 0 })),
        top5: Array.from({ length: 5 }, () => ({ id: -1, label: '#######', total: 0 })),
      };
    }

    return {
      entityList: data?.data,
      top5: data?.top5,
    };
  }, [data, filterState?.isRestricted]);

  const onChangeHandler = (option, isChecked) => {
    if (isChecked) {
      track.click(
        trackSearchFilter(aggregationType, option?.label || option?.name, option?.slug),
        GOOGLE_OLD_TRACKING_SERVICES,
      );
      setSelectedOptions(values => uniqBy([...(values || []), option], 'id'));
    } else {
      setSelectedOptions(values => {
        const finalValues = values?.filter(value => value?.id !== option?.id);

        if (finalValues?.length === 0) {
          return null;
        }

        return finalValues;
      });
    }
  };

  const selectedOptionIds = useMemo(() => selectedOptions?.map(option => option?.id), [selectedOptions]);
  const stopPropagation = useCallback(e => e.stopPropagation(), []);

  const valueIsChanged = useMemo(() => {
    if (!appliedFilters && !selectedOptions?.length) {
      return false;
    }

    if (appliedFilters?.length !== selectedOptions?.length) {
      return true;
    }

    const appliedFilterIds = appliedFilters?.map(filter => filter?.id);
    const selectedFilterIds = selectedOptions?.map(filter => filter?.id);

    return !appliedFilterIds?.every(id => selectedFilterIds?.includes(id));
  }, [appliedFilters, selectedOptions]);

  return (
    <div
      className={classnames(classes.filterDropdown, { [classes.filterDropdownInline]: !!inline })}
      onClick={stopPropagation}
    >
      <div className={classes.filterDropdown__wrapper}>
        <div className={classnames(classes.filterDropdown__content, { [classes.blur]: filterState?.isRestricted })}>
          <Typography className={classes.filterDropdown__title} weight="medium">
            {t(`${TP}.FN_SELECT`)} {t(FILTER_LABELS[type])} {total && `(${total})`}
          </Typography>
          <div
            className={classnames(classes.filterDropdown__header, {
              [classes.filterDropdown__header_popover]: asPopover,
            })}
          >
            <div
              className={classnames(classes.filterDropdown__header_input, {
                [classes.filterDropdown__header_input_popover]: asPopover,
              })}
            >
              <SearchInput onChange={setQuery} placeholder={t(`${TP}.SEARCH`)} />
            </div>
          </div>

          <div className={classes.filterDropdown__options}>
            {top5?.length >= 5 && !query && !isLoading && (
              <div className={classes.filterDropdown__options_maxCountOptions}>
                <Typography color="secondary" size={12} className={classes.topResultText}>
                  {t(`${TP}.FN_BY_MOST_RESULTS`)}
                </Typography>
                <FilterOptions
                  key={type}
                  type={type}
                  options={top5}
                  onChange={onChangeHandler}
                  selectedOptionIds={selectedOptionIds}
                  aggregationType={aggregationType}
                  trackingData={{ ...trackingData, category: CATEGORIES.RECOMMENDED }}
                />
              </div>
            )}
            <Typography weight="bold" size={12} className={classes.allResultsText}>
              {t(`${TP}.FN_ALL_TYPE`, { type: t(PLACEHOLDERS[type]?.MULTIPLE) })}
            </Typography>
            {isLoading ? (
              <Loader />
            ) : (
              <FilterOptions
                key={type}
                type={type}
                options={entityList}
                onChange={onChangeHandler}
                selectedOptionIds={selectedOptionIds}
                aggregationType={aggregationType}
                trackingData={{ ...trackingData, category: query ? CATEGORIES.SEARCH : undefined, searchQuery: query }}
              />
            )}
          </div>
        </div>
      </div>

      <LockedFilters {...filterState} onAccessArchives={onAccessArchives} />

      <div className={classes.filterDropdown__footer}>
        <LinkButton
          rightIcon={<SpriteIcon icon="replay-bold" />}
          variant="text"
          size="medium"
          shape="rectangle"
          onClick={() => {
            setSelectedOptions([]);
          }}
          disabled={!selectedOptions?.length}
          styles={{
            root: classnames(classes.clearSearch, {
              [classes.clearSearch__disabled]: !selectedOptions?.length,
            }),
            icon: classnames(classes.filterDropdown__footer_icon, {
              [classes.filterDropdown__footer_iconActive]: selectedOptions?.length,
            }),
          }}
          disableUnderline
          trackingData={{
            ...trackingData,
            subComponent: trackingData.subComponent
              ? `${trackingData.subComponent} / ${SUB_COMPONENTS.CLEAR_CTA}`
              : SUB_COMPONENTS.CLEAR_CTA,
          }}
        >
          {t(`${TP}.AS_FILTERS_CLEAR_ALL_BTN`)}
        </LinkButton>
        <LinkButton
          styles={{
            root: classnames(classes.submitSearch, {
              [classes.submitSearch__disabled]: !valueIsChanged,
            }),
          }}
          variant="orange"
          shape="rectangle"
          size="medium"
          onClick={() => onUpdateFilters(selectedOptions)}
          disabled={!valueIsChanged}
          skipTracking // tracked in track.click in FilterFacets
        >
          {t(`${TP}.m_APPLY`)}
        </LinkButton>
      </div>
    </div>
  );
};

export default AggregationFilter;
